* {
  padding: 0;
  margin: 0;
  color: #efefd0;
  font-family: "Figtree", sans-serif;
}

body {
  overflow-x: hidden;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  background-image: url("../images/layered-waves-haikei.svg");
  background-repeat: no-repeat;
  background-size: cover;
  flex-wrap: wrap;
  height: 110vh;
  width: 100vw;
}

.heading {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 13rem 6rem;
  justify-content: flex-start;
  align-items: center;
}

.headinglines {
  font-weight: bold;
  color: #004e89;
}

.image {
  height: 25%;
  width: 25%;
}

#lineone {
  font-size: 8vw;
  font-family: "League Gothic", sans-serif;
  text-shadow: 7px 5px 1px #ff6b34;
  overflow: hidden;
  border-right: 0.15em solid orange;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

.orgname {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(250, 114, 104);
  }
}

#linetwo {
  font-family: "Figtree", sans-serif;
  font-size: 4rem;
}

.aboutus {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-items: center;
  padding: 10rem;
  align-items: center;
  margin-top: -25%;
  background-color: #c62368;
  z-index: -1;
  position: relative;
  background-image: url("../images/layered-waves-haikei_2.svg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  height: 100vh;
}
.Textbox {
  background-color: whitesmoke;
  border-radius: 20px;
  margin-top: 5rem;
  width: 30vw;
}

#aboutus_billi_logo {
  width: 30vw;
  height: 30vh;
  fill: white !important;
  box-sizing: border-box;
}

#aboutusright {
  font-family: "Figtree", sans-serif;
  font-size: 2rem;
  background: rgba(255, 255, 255, 0.27);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 25px;
  padding: 2.5rem;
  text-align: justify;
}

.techstackheading {
  background-image: url("../images/waves3.svg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.techstackheading_p {
  font-family: "League Gothic", sans-serif;
  font-size: 5rem;
  font-weight: bolder;
  padding-bottom: 15rem;
  text-shadow: 5px 5px #c62368;
}
.Button_login {
  background-color: #ff6b34;
  border-radius: 20px;
  margin-top: 5rem;
  /* padding: 2rem; */
  width: 10vw;
  height: 7vh;
  font-size: 1.1rem;
}

.techstack {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.tech {
  background-color: #0d0d0d;
  border-radius: 25px;
  height: 10rem;
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.techimg {
  height: 7rem;
  width: 7rem;
}

.top_contributors_content_1,
.top_contributors_content_2 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 5%;
  width: 100%;
}

.top_contributors {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 2rem;
  background-color: #000214;
  flex-wrap: wrap;
}

.top_contributors_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  flex-wrap: wrap;
  padding-top: 5rem;
}

.top_contributors_content_inner {
  font-family: "Figtree", sans-serif;
  background: rgba(193, 167, 167, 0.44);
  border-radius: 25px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.4px);
  -webkit-backdrop-filter: blur(8.4px);
  border: 1px solid rgba(193, 167, 167, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 20rem;
  width: 20rem;
  flex-wrap: wrap;
  overflow: hidden;
}

.top_contributors_content_inner > img {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
}

.top_contributors > p {
  font-size: 5rem;
  font-family: "League Gothic", sans-serif;
  font-weight: bolder;
  text-shadow: 5px 5px #c62368;
}

.top_contributions_contri_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-family: "Figtree", sans-serif;
  z-index: 1;
}

.top_contributions_contri_content > h3 {
  font-size: 1.5rem;
}

.top_contributions_contri_content > h4 {
  font-size: 1rem;
}

.allprojects {
  background: url("../images/stacked-waves-haikei.svg");
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10rem;
}

.allprojects_table {
  background: rgba(255, 255, 255, 0.64);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.9px);
  -webkit-backdrop-filter: blur(8.9px);
  border: 1px solid rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 85vw;
  padding: 1rem 0rem;
  border-radius: 25px;
}

.allprojects_table > p, .allprojects_table > p > b {
  font-size: 1.75rem;
  padding: 1rem;
  text-align: justify;
  color: black;
}

.footer {
  background-color: #fbad3c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footercontent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background: rgba(0, 0, 0, 0.56);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13px);
  -webkit-backdrop-filter: blur(13px);
  border: 1px solid rgba(0, 0, 0, 1);
  font-size: 1.5rem;
  align-items: center;
  height: 200px;
  width: 100vw;
}

.blob {
  position: absolute;
  width: 500px;
  height: 500px;
  background: linear-gradient(
    180deg,
    rgba(47, 184, 255, 0.42) 31.77%,
    #5c9df1 100%
  );
  mix-blend-mode: color-dodge;
  -webkit-animation: move 25s infinite alternate;
  animation: move 25s infinite alternate;
  transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
}

.blob:hover {
  width: 520px;
  height: 520px;
  -webkit-filter: blur(30px);
  filter: blur(30px);
  box-shadow: inset 0 0 0 5px rgba(255, 255, 255, 0.6),
    inset 100px 100px 0 0px #fa709a, inset 200px 200px 0 0px #784ba8,
    inset 300px 300px 0 0px #2b86c5;
}

@-webkit-keyframes move {
  from {
    transform: translate(-500px, -50px) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(500px, 100px) rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}

@keyframes move {
  from {
    transform: translate(-500px, -50px) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(500px, 100px) rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}

.dialog {
  width: 500px;
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 9999;
}

.gfg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;
  border-radius: 25px;
  margin: 1.5rem;
}

/* Media Queries */
@media screen and (max-width: 1200px) {
  .heading {
    padding: 8rem 4rem;
  }
  .aboutus {
    padding: 6rem;
  }
  .aboutusright {
    font-size: 1.8rem;
    padding: 2rem;
  }
  .top_contributors_content_inner {
    height: 16rem;
    width: 16rem;
  }
  .top_contributors > p {
    font-size: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .heading {
    padding: 4rem 2rem;
  }
  #lineone {
    font-size: 4vw;
  }
  #linetwo {
    font-size: 2.5rem;
  }
  .aboutus {
    padding: 4rem;
  }
  .aboutusright {
    font-size: 1.5rem;
    padding: 1.5rem;
  }
  .techstackheading_p {
    font-size: 3rem;
  }
  .tech {
    height: 7rem;
    width: 7rem;
  }
  .top_contributors_content_inner {
    height: 14rem;
    width: 14rem;
  }
  .top_contributors > p {
    font-size: 3rem;
  }
}

@media screen and (max-width: 576px) {
  .heading {
    padding: 4rem 1rem;
  }
  #lineone {
    font-size: 4.5vw;
  }
  #linetwo {
    font-size: 2rem;
  }
  .aboutus {
    padding: 3rem;
  }
  .aboutusright {
    font-size: 1.2rem;
    padding: 1rem;
  }
  .techstackheading_p {
    font-size: 2.5rem;
  }
  .tech {
    height: 6rem;
    width: 6rem;
  }
  .techimg {
    height: 4rem;
    width: 4rem;
  }
  .top_contributors_content_inner {
    height: 12rem;
    width: 12rem;
  }
  .top_contributors > p {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 400px) {
  .techstack {
    overflow-x: scroll;
  }
  .heading {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 3rem 0rem;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0;
  }
  #lineone {
    font-size: 35px;
    font-family: "League Gothic", sans-serif;
    text-shadow: 2px 1px 1px #ff6b34;
    text-align: center;
    padding-left: 0;
    overflow: hidden;
    border-right: 0.15em solid orange;
    white-space: nowrap;
    margin: 0 auto;
    margin-top: 30px;
    margin-left: 8%;
    margin-right: 12.5%;
    letter-spacing: 0.15rem;
    animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  }
  #linetwo {
    text-align: center;
    font-size: 15px;
    padding: 15px;
    margin-left: -10%;
  }
  .Textbox {
    border-radius: 0px;
    margin-top: 30px;
    width: 100%;
    margin-left: -20%;
    /* padding-left: 20%; */
    font-size: 1.1rem !important;
  }
  .Button_login {
    background-color: #ff6b34;
    border-radius: 20px;
    margin-top: 100%;
    margin-left: -30px;
    margin-bottom: -50%;

    width: 40%;
    height: 60px;
    font-size: 10px;
    padding-top: 20%;
  }
  /* .login{
    flex-direction: row;
  } */
  #aboutus_billi_logo {
    width: 100%;
    height: 100%;
    fill: white !important;
    z-index: 1;
    padding-top: 50%;
    margin-left: -10px;
  }
  #aboutusright {
    font-family: "Figtree", sans-serif;
    font-size: 20px;
    text-align: center;
    margin-top: -50%;
  }
  .aboutus {
    display: flex;
    font-size: 12px;
    flex-direction: row;
    /* align-content: center; */
    justify-content: space-around;
    /* justify-items: start ; */
    flex-wrap: wrap;
    padding: 15%;
    /* padding-top: 50rem; */
    align-items: flex-start;
    margin-top: -129px;
    /* background: linear-gradient(#efefd0, #002d4f, #00192c); */
    background-color: #c62368;
    z-index: -1;
    position: relative;
    background-image: url("../images/layered-waves-haikei_2.svg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
    height: 100vh;
  }
  /* #aboutusleft{
    height:10px;
    width:10px;
  } */
  .orgname {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
    height: 6rem;
    margin-top: 2rem;
  }
}
